import * as common from '@/api/common';
import * as userApi from '@/api/user';
import {getAuth, removeAuth, setAuth} from 'Global/helpers/localStorage';

const user = {
  state: {
    id         : false, // Имя пользователя
    user       : false, // Имя пользователя
    token      : false, // Авторизационный ключ
    permissions: [],    // Список прав пользователя
    roles      : [],    // Список ролей пользователя
    superadmin : false // Права суперадмина
  },

  mutations: {
    SET_ID         : (state, id) => {
      state.id = id;
    },
    SET_TOKEN      : (state, token) => {
      state.token = token;
    },
    SET_USER       : (state, user) => {
      state.user = user;
    },
    SET_PERMISSIONS: (state, perm) => {
      state.permissions = perm;
    },
    SET_ROLES      : (state, roles) => {
      state.roles = roles;
    },
    SET_SUPERADMIN : (state, val) => {
      state.superadmin = val;
    }
  },

  actions: {
    /**
     * Получения у установка авторизационных данных пользователя
     *
     * @param commit
     * @param dispatch
     */
    async auth({commit, dispatch}) {
      const authInfo = getAuth();

      if (authInfo.user && authInfo.token) {
        commit('SET_ID', authInfo.id);
        commit('SET_USER', authInfo.user);
        commit('SET_TOKEN', authInfo.token);

        await Promise.all([
          dispatch('getPermissions')
        ]);
      }

      return Promise.resolve();
    },
    /**
     * Запрос и установка прав пользователя
     *
     * @param commit
     * @returns {Promise<any>}
     */
    async getPermissions({commit}) {
      return new Promise((resolve, reject) => {
        common.getPermissions()
          .then(data => {
            commit('SET_PERMISSIONS', data.permissions);

            if (data.roles) {
              commit('SET_ROLES', data.roles);
            }

            if (data.superadmin) {
              commit('SET_SUPERADMIN', data.superadmin);
            }
            resolve(data);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    /**
     * Авторизация пользователя и установка данных
     *
     * @param commit
     * @param userInfo
     * @returns {Promise<any>}
     */
    login({commit}, userInfo) {
      const username = userInfo.username.trim();
      return new Promise((resolve, reject) => {
        common.login(username, userInfo.password)
          .then(data => {
            commit('SET_ID', data.id);
            commit('SET_USER', data.user);
            commit('SET_TOKEN', data.token);
            setAuth(data.id, data.user, data.token);
            resolve(data);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    /**
     * Запрос на выход пользователя
     *
     * @param commit
     * @param state
     * @returns {Promise<any>}
     */
    logout({commit, state}) {
      return new Promise((resolve, reject) => {
        // common.logout()
        // 	  .then(() => {
        commit('SET_ID', false);
        commit('SET_USER', false);
        commit('SET_TOKEN', false);
        removeAuth();
        resolve();
        // })
        // .catch(error => {
        //   reject(error);
        // });
      });
    },

    getRoles({commit}) {
      return new Promise((resolve, reject) => {
        userApi.getRoles()
          .then(data => {
            commit('SET_ROLES', data);
            resolve(data);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
  }
};

export default user;
