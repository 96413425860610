import * as API from '@/helpers/request';

const controllerPath = 'order';

export function getItems(filter, perPage, page, sort) {
  return API.getItems(controllerPath, filter, perPage, page, sort);
}

export function getItemsList(data) {
  return API.getItemsList(controllerPath, data);
}

export async function getItem(data, withoutDataTransform = false) {
  if (withoutDataTransform) {
    return API.getItem(controllerPath, data)
  }
  else {
    try {
      let order = await API.getItem(controllerPath, data)

      if (order.parent) {
        order.parent = order.parent.id
      }

      if (order.organization) {
        order.organization = order.organization.id
      }

      if (order.contact) {
        order.contact = order.contact.id
      }

      order.currency = order.currency.id

      return order
    }
    catch (e) {
      console.log(e)
    }
  }
}

export function createItem(data) {
  return API.createItem(controllerPath, data);
}

export function updateItem(id, data) {
  return API.updateItem(controllerPath, id, data);
}

export function deleteItem(data) {
  return API.deleteItem(controllerPath, data);
}

export function getStatusesList() {
  return API.request.post(controllerPath + '/statuses-list');
}

export function addStands(id, data) {
  return API.request.patch(`${controllerPath}/${id}/add-stands`, data);
}

export function removeStand(id, standId) {
  return API.request.delete(`${controllerPath}/${id}/remove-stand/${standId}`);
}

export function getStands(id) {
  return API.request.post(`${controllerPath}/${id}/get-stands`);
}