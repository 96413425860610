import * as API from '@/helpers/request';

const controllerPath = 'email-log';

export function getItems(filter, perPage, page, sort) {
  return API.getItems(controllerPath, filter, perPage, page, sort);
}

export function getItem(data) {
  return API.getItem(controllerPath, data)
}