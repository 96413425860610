import * as API from '@/helpers/request';
import {AxiosResponse} from 'axios';

/**
 * Авторизация пользователя
 *
 * @param user
 * @param pass
 * @returns {Promise<AxiosResponse<T>>}
 */
export function login(user, pass) {
  return API.request.post('/login', {
    email   : user,
    password: pass
  });
}

/**
 * Выход пользователя
 *
 * @returns {Promise<AxiosResponse<T>>}
 */
export function logout() {
  return API.request.post('/logout');
}

/**
 * Получение списка прав
 *
 * @returns {Promise<AxiosResponse<T>>}
 */
export function getPermissions() {
  return API.request.post('/permissions');
}

/**
 * Получения массива бокового меню
 *
 * @returns {Promise<AxiosResponse<T>>}
 */
export function getSidebarMenu() {
  return API.request.post('/sidebar/menu');
}

export function save1cApiSettings(data) {
  return API.request.post('/1c-api-settings/save', data);
}
