import * as API from '@/helpers/request';

const controllerPath = 'product';

export function getItems(filter, perPage, page, sort) {
  return API.getItems(controllerPath, filter, perPage, page, sort);
}

export function getItemsList(data) {
  return API.getItemsList(controllerPath, data);
}

export async function getItem(data) {
  try {
    let product = await API.getItem(controllerPath, data)

    if (product.category) {
      product.category = product.category.id
    }

    if (product.unit) {
      product.unit = product.unit.id
    }

    if (product.participation_category) {
      product.participation_category = product.participation_category.id
    }

    return product
  }
  catch (e) {
    console.log(e)
  }
}

export function createItem(data) {
  return API.createItem(controllerPath, data);
}

export function updateItem(id, data) {
  return API.updateItem(controllerPath, id, data);
}

export function deleteItem(data) {
  return API.deleteItem(controllerPath, data);
}

export function uploadFile(data) {
  return API.request.post(`${controllerPath}/upload-xls`, data)
}