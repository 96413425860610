import * as API from '@/helpers/request';

const controllerPath = 'contact';

export function getItems(filter, perPage, page, sort) {
  return API.getItems(controllerPath, filter, perPage, page, sort);
}

export function getItemsList(data) {
  return API.getItemsList(controllerPath, data);
}

export async function getItem(data) {
  try {
    let contact = await API.getItem(controllerPath, data)

    if (contact.organization) {
      contact.organizationAlfaContactId = contact.organization.alfa_contact_id
      contact.organization = contact.organization.id
    }

    if (contact.participation_categories && contact.participation_categories.length > 0) {
      contact.participation_categories = contact.participation_categories[0].id
    }
    else {
      contact.participation_categories = null
    }

    return contact
  }
  catch (e) {
    console.log(e)
  }
}

export function createItem(data) {
  data.participation_categories = [data.participation_categories]
  return API.createItem(controllerPath, data);
}

export function updateItem(id, data) {
  data.participation_categories = [data.participation_categories]
  return API.updateItem(controllerPath, id, data);
}

export function deleteItem(id) {
  return API.deleteItem(controllerPath, id);
}

export function getStatusesList() {
  return API.request.post(controllerPath + '/statuses-list');
}

export function changePassword(id, data) {
  return API.request.post(`${controllerPath}/${id}/change-password`, data);
}

export function hasBadge(id) {
  return API.request.post(`${controllerPath}/${id}/has-badge`);
}

export function createBadge(id, data) {
  return API.request.post(`${controllerPath}/${id}/create-badge`, data);
}

export function deleteBadge(id) {
  return API.request.post(`${controllerPath}/${id}/delete-badge`);
}

export function sendBadge(id) {
  return API.request.post(`${controllerPath}/${id}/send-badge`);
}
