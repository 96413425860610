import * as API from '@/helpers/request';

const controllerPath = 'mailing-subscriber';

export function getItems(filter, perPage, page, sort) {
  return API.getItems(controllerPath, filter, perPage, page, sort);
}

export function getItemsList(data) {
  return API.getItemsList(controllerPath, data);
}

export async function getItem(data) {
  try {
    let subscriber = await API.getItem(controllerPath, data)

    if (subscriber.contact) {
      subscriber.contact = subscriber.contact.id
    }

    return subscriber
  }
  catch (e) {
    console.log(e)
  }
}

export function createItem(data) {
  return API.createItem(controllerPath, data);
}

export function updateItem(id, data) {
  return API.updateItem(controllerPath, id, data);
}

export function deleteItem(id) {
  return API.deleteItem(controllerPath, id);
}
