import * as API from '@/helpers/request';
import _ from "lodash";

const controllerPath = 'exhibitor';

export function getItems(filter, perPage, page, sort) {
  return API.getItems(controllerPath, filter, perPage, page, sort);
}

export function getItemsList(data) {
  return API.getItemsList(controllerPath, data);
}

export async function getItem(data) {
  try {
    let exhibitor = await API.getItem(controllerPath, data)

    // sections
    let sectionIds = []
    _.forEach(exhibitor.sections, (section) => {
      sectionIds.push(section.id)
    })

    exhibitor.sections = sectionIds

    // stands
    let standIds = []

    _.forEach(exhibitor.stands, (stand) => {
      standIds.push(stand.id)
    })

    exhibitor.stands = standIds

    // organization
    if (exhibitor.organization) {
      exhibitor.organizationObj = exhibitor.organization
      exhibitor.organization = exhibitor.organization.id
    }

    if (exhibitor.country) {
      exhibitor.country = exhibitor.country.id
    }

    return exhibitor
  }
  catch (e) {
    console.log(e)
  }
}

export function createItem(data) {
  return API.createItem(controllerPath, data);
}

export function updateItem(id, data) {
  return API.updateItem(controllerPath, id, data);
}

export function deleteItem(id) {
  return API.deleteItem(controllerPath, id);
}

export function getStatusesList() {
  return API.request.post(controllerPath + '/statuses-list');
}

export function getAddressTypesList() {
  return API.request.post(controllerPath + '/address-types-list');
}

export function excelBySection() {
  return API.downloadFile(
    `${controllerPath}/excel-by-section`,
    {},
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    `exhibitors-and-sections.xlsx`
  );
}