import * as API from '@/helpers/request';
import _ from "lodash";

const controllerPath = 'event';

export function getItems(filter, perPage, page, sort) {
  return API.getItems(controllerPath, filter, perPage, page, sort);
}

export function getItemsList(data) {
  return API.getItemsList(controllerPath, data);
}

export async function getItem(data) {
  try {
    let event = await API.getItem(controllerPath, data)

    event.categories = _.map(event.categories, 'id')
    event.moderators = _.map(event.moderators, 'id')
    event.venues = _.map(event.venues, 'id')

    if (event.type) {
      event.type = event.type.id
    }

    if (event.program) {
      event.program = event.program.id
    }

    if (event.participation_category) {
      event.participation_category = event.participation_category.id
    }

    if (event.time_start) {
      event.time_start = event.time_start.substring(11, 19)
    }

    if (event.time_end) {
      event.time_end = event.time_end.substring(11, 19)
    }

    event.speakers = _.map(event.speakers, 'id')

    if (!event.order_speakers) {
      event.order_speakers = JSON.stringify(event.speakers)
    }

    event.speakers = JSON.parse(event.order_speakers)

    return event
  }
  catch (e) {
    console.error(e)
  }
}

export function createItem(data) {
  return API.createItem(controllerPath, data);
}

export function updateItem(id, data) {
  return API.updateItem(controllerPath, id, data);
}

export function deleteItem(id) {
  return API.deleteItem(controllerPath, id);
}

export function uploadPdfProgram(data) {
  return API.request.post('business-program/upload', data);
}
