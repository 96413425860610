import * as API from '@/helpers/request';

const controllerPath = 'mailer';

export function sendContactAccess(id) {
  return API.request.post(`${controllerPath}/send-contact-access`, {id: id})
}

export function sendMailing(id) {
  return API.request.post(`${controllerPath}/send-mailing`, {id: id})
}

export function sendMailingTemplate(data) {
  return API.request.post(`${controllerPath}/send-mailing-template`, data)
}