import 'codemirror/mode/twig/twig';
import 'codemirror/mode/htmlmixed/htmlmixed';
import 'codemirror/mode/css/css';
import 'codemirror/addon/edit/closetag';
import 'codemirror/addon/display/fullscreen';
import 'codemirror/addon/selection/active-line';
import 'codemirror/addon/display/autorefresh';
/**
 * Подключение UI и глобальных стилей
 */
import '@/styles/import.scss';
/**
 * Импорт главного представления
 */
import App from './App';
/**
 * Импорт системных компонентов
 */
import Vue from 'vue';
import Vuelidate from 'vuelidate'
import VueRouter from 'vue-router';
import {library} from '@fortawesome/fontawesome-svg-core';
import {fas} from '@fortawesome/free-solid-svg-icons';
import {far} from '@fortawesome/free-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import VueTheMask from 'vue-the-mask';
import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';
import VueMoment from 'vue-moment';
import {BootstrapVue, BootstrapVueIcons} from 'bootstrap-vue';
import {VueTelInput} from 'vue-tel-input';
import CustomInput from 'Global/components/CustomInput/index';
import GridView from 'Global/components/GridView/index';
import GridFilter from 'Global/components/GridView/GridFilter';
import VueObserveVisibility from 'vue-observe-visibility';
import VueCodemirror from 'vue-codemirror';
import DatePicker from 'vue2-datepicker';
import vSelect from 'vue-select';
import 'vue2-datepicker/locale/ru';
import ElementLocaleRu from 'element-ui/lib/locale/lang/ru-RU';
import ElementLocale from 'element-ui/lib/locale';
import {Cascader, ColorPicker, Form, Menu, MenuItem, Option, OptionGroup, Select, Submenu, Switch} from 'element-ui';

/**
 * Импорт системных компонентов
 */
import Api from './api';
import router from './router';
import store from './store';
/**
 * Глобальные директивы
 */
import 'Global/directives';
/**
 * Импорт глобальных фильтров
 */
import * as filters from 'Global/filters';
/**
 * Регистрация шины событий
 */
import GlobalBus from 'Global/components/GlobalBus';
/**
 * Импорт вспомогательных функций
 */
import VueLodash from 'Global/components/Lodash';

/**
 * Настройки
 */
import SettingsProvider from 'Global/helpers/settings/SettingsProvider';

Vue.prototype.$settings = SettingsProvider;

/**
 * Регистрация библиотеки для работы с датой и временем
 */
momentDurationFormatSetup(moment);
require('moment/locale/ru');
Vue.use(VueMoment, {moment});

/**
 * Регистрация библиотек значков
 */
library.add(fas);
library.add(far);
Vue.component('font-awesome-icon', FontAwesomeIcon);
/**
 * Регистрация глобальных фильтров
 */
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key]);
});
/**
 * Регистрация сторонних компонентов
 */
Vue.use(Vuelidate);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(VueTheMask);
Vue.component('v-select', vSelect);
Vue.component('phone-input', VueTelInput);
Vue.component('custom-input', CustomInput);
Vue.component('grid-view', GridView);
Vue.component('grid-filter', GridFilter);
Vue.use(VueObserveVisibility);
Vue.use(VueCodemirror, {
  options: {
    mode           : {name: 'twig', base: 'text/html'},
    tabSize        : 4,
    styleActiveLine: true,
    lineNumbers    : true,
    autoCloseTags  : true,
    autoRefresh    : true,
    extraKeys      : {
      'F11': function (cm) {
        cm.setOption('fullScreen', !cm.getOption('fullScreen'));
      },
      'Esc': function (cm) {
        if (cm.getOption('fullScreen')) {
          cm.setOption('fullScreen', false);
        }
      }
    }
  }
});

DatePicker.props.titleFormat.default = 'DD.MM.YYYY';
DatePicker.props.timeTitleFormat.default = 'DD.MM.YYYY';
DatePicker.props.valueType.default = 'YYYY-MM-DD';
DatePicker.props.format.default = 'DD.MM.YYYY';
DatePicker.props.editable.default = false;
DatePicker.props.inputClass.default = 'form-control';
DatePicker.props.lang.default = () => {
  return {monthFormat: 'MMMM'};
};
Vue.component('date-picker', DatePicker);

ElementLocale.use(ElementLocaleRu);
// import components
Vue.component(Menu.name, Menu);
Vue.component(Submenu.name, Submenu);
Vue.component(MenuItem.name, MenuItem);
Vue.component(Form.name, Form);
Vue.component(Cascader.name, Cascader);
Vue.component(ColorPicker.name, ColorPicker);
Vue.component(Select.name, Select);
Vue.component(Option.name, Option);
Vue.component(OptionGroup.name, OptionGroup);
Vue.component(Switch.name, Switch);
/**
 * Регистрация системных компонентов
 */
Vue.use(VueRouter);
Vue.use(Api);
Vue.use(VueLodash);
Vue.use(GlobalBus);

Vue.filter('truncate', function (text, stop, clamp) {
  return text.slice(0, stop) + (stop < text.length ? clamp || '...' : '')
})

let app = new Vue({});
store.dispatch('auth')
  .finally(() => {
    /**
     * Регистрация приложения
     */
    app = new Vue({
      el        : '#app',
      router,
      store,
      template  : '<App/>',
      components: {App}
    });
  });

export default app;