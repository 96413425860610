import * as API from '@/helpers/request';
import {AxiosResponse} from 'axios';

const controllerPath = 'user';

/**
 * Список всех пользователей
 *
 * @param filter
 * @param perPage
 * @param page
 * @param sort
 * @returns {Promise<AxiosResponse<T>>}
 */
export function getItems(filter, perPage, page, sort) {
  return API.getItems(controllerPath, filter, perPage, page, sort);
}

/**
 * Список всех пользователей с укороченной информацией [id, username]
 *
 * @returns {Promise<AxiosResponse<T>>}
 */
export function getItemsList(data) {
  return API.getItemsList(controllerPath, data, ['id', 'email']);
}

export function getRolesList() {
  return API.request.post(controllerPath + '/roles-list');
}

/**
 * Информация о пользователе
 *
 * @param data
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getItem(data) {
  try {
    let user = await API.getItem(controllerPath, data)

    user.password = null;

    return user
  }
  catch (e) {
    console.error(e)
  }
}

/**
 * Создание пользователя
 *
 * @param data
 * @returns {Promise<AxiosResponse<T>>}
 */
export function createItem(data) {
  if (data.password === null || data.password === '') {
    delete data.password
  }

  return API.createItem(controllerPath, data);
}

/**
 * Изменение пользователя
 *
 * @param id
 * @param data
 * @returns {Promise<AxiosResponse<T>>}
 */
export function updateItem(id, data) {
  if (data.password === null || data.password === '') {
    delete data.password
  }

  return API.updateItem(controllerPath, id, data);
}

/**
 * Удаление пользователя
 *
 * @param id
 * @returns {Promise<AxiosResponse<T>>}
 */
export function deleteItem(id) {
  return API.deleteItem(controllerPath, id);
}

/**
 * Получение настроек пользователя
 *
 * @returns {Promise<AxiosResponse<T>>}
 */
export function getPermissions(app, id) {
  return true;
  return API.request.post(`/${controllerPath}/permissions`, {app, id});
}

/**
 * Запись настроек пользователя
 *
 * @returns {Promise<AxiosResponse<T>>}
 */
export function setPermissions(app, id, childRoles) {
  return true;
  return API.request.post(`/${controllerPath}/set-permissions`, {app, id, childRoles});
}

export function changeEmail(id, newEmail) {
  return API.request.post(`/${controllerPath}/change-email`, {id, newEmail});
}

export function changePassword(id, newPassword) {
  return API.request.post(`/${controllerPath}/change-password`, {id, newPassword});
}
