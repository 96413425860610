<template>
	<div id="admin-client">
		<router-view/>
	</div>
</template>

<script>
	export default {
		name : 'admin-client',
	};
</script>
