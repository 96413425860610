import * as API from '@/helpers/request';

const controllerPath = 'mailing-group';

export function getItems(filter, perPage, page, sort) {
  return API.getItems(controllerPath, filter, perPage, page, sort);
}

export function getItemsList(data) {
  return API.getItemsList(controllerPath, data);
}

export function getItem(data) {
  return API.getItem(controllerPath, data)
}

export function createItem(data) {
  return API.createItem(controllerPath, data);
}

export function updateItem(id, data) {
  return API.updateItem(controllerPath, id, data);
}

export function deleteItem(id) {
  return API.deleteItem(controllerPath, id);
}

export function uploadFile(id, data) {
  return API.request.post(`${controllerPath}/${id}/upload-xls`, data)
}

export function addSubscriber(id, data) {
  return API.request.patch(`${controllerPath}/${id}/add-subscriber`, data)
}

export function deleteSubscriber(id, subscriberId) {
  return API.request.patch(`${controllerPath}/${id}/delete-subscriber`, {subscriberId})
}
