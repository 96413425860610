import * as API from '@/helpers/request';

const controllerPath = 'document';

export function getItems(filter, perPage, page, sort) {
  return API.getItems(controllerPath, filter, perPage, page, sort);
}

export function getItemsList(data) {
  return API.getItemsList(controllerPath, data);
}

export async function getItem(data) {
  return API.getItem(controllerPath, data)
}

export function createItem(data) {
  return API.createItem(controllerPath, data);
}

export function updateItem(id, data) {
  return API.updateItem(controllerPath, id, data);
}

export function deleteItem(id) {
  return API.deleteItem(controllerPath, id);
}

export function getStatusesList() {
  return API.request.post(controllerPath + '/statuses-list');
}

export function getTypeList() {
  return API.request.post(controllerPath + '/type-list');
}

export function createFromTemplate(id, format) {
  return API.request.post(controllerPath + '/create-from-template', {id, format});
}

export function createUploadArchive(data, filename) {
  return API.downloadFile(
    `${controllerPath}/create-upload-archive`,
    data,
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    filename
  );
}