import {format} from "Global/apps/site/src/helpers/moneyFormatter";

const order = {
  state: {
    totalSum   : '',
    totalSumVat: '',
  },

  mutations: {
    SET_TOTAL_SUM    : (state, sum) => {
      state.totalSum = sum;
    },
    SET_TOTAL_SUM_VAT: (state, sum) => {
      state.totalSumVat = sum;
    },
  },

  actions: {
		getOrderTotalSum({commit}, items) {
			commit('SET_TOTAL_SUM', '')
			commit('SET_TOTAL_SUM_VAT', '')

			if (!items || items.length < 1) {
				return
			}

			const prices = _.map(items, (v) => {
				const sum = Number(v.price.price) * Number(v.count)
				const vat = Number(v.product.vat)
				const sumVat = (sum / (100 + vat) * vat);

				return {sum, sumVat}
			})

			let totalSum = _.sum(_.map(prices, (v) => v.sum))
			let totalSumVat = _.sum(_.map(prices, (v) => v.sumVat))

			commit('SET_TOTAL_SUM', format(totalSum))
			commit('SET_TOTAL_SUM_VAT', format(totalSumVat))
		},
  }
};

export default order;
