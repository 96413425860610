export const formatter = new Intl.NumberFormat('ru-RU', {
  style                : 'currency',
  currency             : 'EUR',
  signDisplay          : 'never',
  roundingMode         : 'ceil',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
})

export const format = (number) => {
  if (!number) {
    return ''
  }

  const regex = /[^0-9.,\s]+/giu;

  number = number.toFixed(2)

  return formatter.format(number).replaceAll(regex, '')
}