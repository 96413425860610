import * as API from '@/helpers/request';

const controllerPath = 'email-template';

export function getItems(filter, perPage, page, sort) {
  return API.getItems(controllerPath, filter, perPage, page, sort);
}

export function getItemsList(data) {
  return API.getItemsList(controllerPath, data);
}

export function getItem(data) {
  return API.getItem(controllerPath, data);
}

export function createItem(data) {
  return API.createItem(controllerPath, data);
}

export function updateItem(id, data) {
  return API.updateItem(controllerPath, id, data);
}

export function deleteItem(id) {
  return API.deleteItem(controllerPath, id);
}

export function getTypeList(unusedOnly = null) {
  return API.request.post(controllerPath + '/type-list', {unusedOnly});
}

export function getVariableDescription(type) {
  return API.request.post(controllerPath + '/get-variable-description', {type});
}

export function getCommonSettings() {
  return API.request.post(controllerPath + '/get-common-settings');
}

export function updateCommonSettings(data) {
  return API.request.post(controllerPath + '/update-common-settings', data);
}

export function copyTemplate(id) {
  return API.request.post(`${controllerPath}/copy-template/${id}`);
}
