import VueRouter from 'vue-router';
import qs from 'qs';

const routes = [
  {
    path     : '/login',
    name     : 'login',
    component: () => import('@/views/login/index')
  },
  {
    path     : '*',
    component: () => import('@/views/layout/Main'),
    redirect : '/404',
    children : [
      {
        path     : '/404',
        component: () => import('@/views/error/404'),
        name     : 'notFound',
        meta     : {title: 'Страница не найдена'}
      }
    ]
  },
  {
    path     : '/',
    component: () => import('@/views/layout/Main'),
    redirect : 'dashboard',
    children : [
      {
        path     : 'dashboard',
        component: () => import('@/views/dashboard/index'),
        name     : 'dashboard',
        meta     : {title: 'Рабочий стол'}
      }
    ]
  }
];
routes.push({
  path     : '/user',
  component: () => import('@/views/layout/Main'),
  redirect : '/user/index',
  children : [
    {
      path     : 'index',
      component: () => import('@/views/user/index'),
      name     : 'userIndex',
      meta     : {title: 'Пользователи'}
    },
    {
      path     : 'create',
      component: () => import('@/views/user/form'),
      name     : 'userCreate',
      meta     : {title: 'Создание пользователя', parent: ['userIndex']}
    },
    {
      path     : 'update/:id',
      component: () => import('@/views/user/form'),
      name     : 'userUpdate',
      meta     : {title: 'Изменение пользователя', parent: ['userIndex'], titleParam: ['id']}
    }
  ]
});
routes.push({
  path     : '/user-log',
  component: () => import('@/views/layout/Main'),
  redirect : '/user-log/index',
  children : [
    {
      path     : 'index',
      component: () => import('@/views/userLog/index'),
      name     : 'userLogIndex',
      meta     : {title: 'Лог действий пользователя'}
    }
  ]
});

// Документация
routes.push({
  path     : '/documentation',
  name     : 'documentationIndex',
  component: () => import('@/views/layout/Main'),
  redirect : '/documentation/common',
  meta     : {title: 'Документация'},

  children: [
    {
      path     : 'common',
      component: () => import('@/components/DashboardDocs/index'),
      name     : 'DashboardDocs',
      meta     : {title: 'Общая', parent: ['documentationIndex']}
    }
  ]
});
routes.push({
  path     : '/settings',
  component: () => import('@/views/layout/Main'),
  redirect : '/settings/index',
  children : [
    {
      path     : 'index',
      component: () => import('@/views/settings/index'),
      name     : 'settingsIndex',
      meta     : {title: 'Общие настройки'}
    },
    {
      path     : 'update/:id',
      component: () => import('@/views/settings/form'),
      name     : 'settingUpdate',
      meta     : {
        title     : 'Изменение настройки',
        parent    : ['settingsIndex'],
        titleParam: ['id']
      }
    }
  ]
});
routes.push({
  path     : '/country',
  component: () => import('@/views/layout/Main'),
  redirect : '/country/index',
  children : [
    {
      path     : 'index',
      component: () => import('@/views/country/index'),
      name     : 'countryIndex',
      meta     : {title: 'Страны'}
    },
    {
      path     : 'create',
      component: () => import('@/views/country/form'),
      name     : 'countryCreate',
      meta     : {title: 'Создание страны', parent: ['countryIndex']}
    },
    {
      path     : 'update/:id',
      component: () => import('@/views/country/form'),
      name     : 'countryUpdate',
      meta     : {
        title: 'Изменение страны', parent: ['countryIndex'], titleParam: ['id']
      }
    }
  ]
});
routes.push({
  path     : '/unit',
  component: () => import('@/views/layout/Main'),
  redirect : '/unit/index',
  children : [
    {
      path     : 'index',
      component: () => import('@/views/unit/index'),
      name     : 'unitIndex',
      meta     : {title: 'Единицы измерений'}
    },
    {
      path     : 'create',
      component: () => import('@/views/unit/form'),
      name     : 'unitCreate',
      meta     : {title: 'Создание единицы измерения', parent: ['unitIndex']}
    },
    {
      path     : 'update/:id',
      component: () => import('@/views/unit/form'),
      name     : 'unitUpdate',
      meta     : {
        title: 'Изменение единицы измерения', parent: ['unitIndex'], titleParam: ['title']
      }
    }
  ]
});
routes.push({
  path     : '/section',
  component: () => import('@/views/layout/Main'),
  redirect : '/section/index',
  children : [
    {
      path     : 'index',
      component: () => import('@/views/section/index'),
      name     : 'sectionIndex',
      meta     : {title: 'Разделы участия'}
    },
    {
      path     : 'create',
      component: () => import('@/views/section/form'),
      name     : 'sectionCreate',
      meta     : {title: 'Создание раздела участия', parent: ['sectionIndex']}
    },
    {
      path     : 'update/:id',
      component: () => import('@/views/section/form'),
      name     : 'sectionUpdate',
      meta     : {
        title: 'Изменение раздела участия', parent: ['sectionIndex'], titleParam: ['id']
      }
    }
  ]
});
routes.push({
  path     : '/participation-category',
  component: () => import('@/views/layout/Main'),
  redirect : '/participation-category/index',
  children : [
    {
      path     : 'index',
      component: () => import('@/views/participationCategory/index'),
      name     : 'participationCategoryIndex',
      meta     : {title: 'Категории участия'}
    },
    {
      path     : 'create',
      component: () => import('@/views/participationCategory/form'),
      name     : 'participationCategoryCreate',
      meta     : {title: 'Создание категории участия', parent: ['participationCategoryIndex']}
    },
    {
      path     : 'update/:id',
      component: () => import('@/views/participationCategory/form'),
      name     : 'participationCategoryUpdate',
      meta     : {
        title: 'Изменение категории участия', parent: ['participationCategoryIndex'], titleParam: ['id']
      }
    }
  ]
});

// Организации
routes.push({
  path     : '/organization',
  name     : 'organization',
  component: () => import('@/views/layout/Main'),
  redirect : '/organization/index',
  meta     : {title: 'Организации'},

  children: [
    {
      path     : 'index',
      component: () => import('@/views/organization/index'),
      name     : 'organizationIndex',
      meta     : {title: 'Организации', parent: ['organizationIndex']}
    },
    {
      path     : 'deactivated',
      component: () => import('@/views/organization/index'),
      name     : 'organizationDeactivated',
      meta     : {title: 'Удаленные организации', parent: ['organizationDeactivated']}
    },
    {
      path     : 'create',
      component: () => import('@/views/organization/form'),
      name     : 'organizationCreate',
      meta     : {title: 'Создание организации', parent: ['organizationIndex']}
    },
    {
      path     : 'update/:id',
      component: () => import('@/views/organization/form'),
      name     : 'organizationUpdate',
      meta     : {
        title: 'Изменение организации', parent: ['organizationIndex'], titleParam: ['id']
      }
    }
  ]
});

// Экспоненты
routes.push({
  path     : '/exhibitor',
  name     : 'exhibitor',
  component: () => import('@/views/layout/Main'),
  redirect : '/exhibitor/index',
  meta     : {title: 'Экспоненты'},

  children: [
    {
      path     : 'index',
      component: () => import('@/views/exhibitor/index'),
      name     : 'exhibitorIndex',
      meta     : {title: 'Экспоненты', parent: ['exhibitorIndex']}
    },
    {
      path     : 'deactivated',
      component: () => import('@/views/exhibitor/index'),
      name     : 'exhibitorDeactivated',
      meta     : {title: 'Удаленные экспоненты', parent: ['exhibitorDeactivated']}
    },
    {
      path     : 'create/:organizationId?',
      component: () => import('@/views/exhibitor/form'),
      name     : 'exhibitorCreate',
      meta     : {title: 'Создание экспонента', parent: ['exhibitorIndex']}
    },
    {
      path     : 'update/:id',
      component: () => import('@/views/exhibitor/form'),
      name     : 'exhibitorUpdate',
      meta     : {
        title: 'Изменение экспонента', parent: ['exhibitorIndex'], titleParam: ['id']
      }
    }
  ]
});

// Контакты
routes.push({
  path     : '/contact',
  name     : 'contact',
  component: () => import('@/views/layout/Main'),
  redirect : '/contact/index',
  meta     : {title: 'Контакты'},

  children: [
    {
      path     : 'index',
      component: () => import('@/views/contact/index'),
      name     : 'contactIndex',
      meta     : {title: 'Контакты', parent: ['contactIndex']}
    },
    {
      path     : 'deactivated',
      component: () => import('@/views/contact/index'),
      name     : 'contactDeactivated',
      meta     : {title: 'Удаленные контакты', parent: ['contactDeactivated']}
    },
    {
      path     : 'create/:organizationId?',
      component: () => import('@/views/contact/form'),
      name     : 'contactCreate',
      meta     : {title: 'Создание контакта', parent: ['contactIndex']}
    },
    {
      path     : 'update/:id',
      component: () => import('@/views/contact/form'),
      name     : 'contactUpdate',
      meta     : {
        title: 'Изменение контакта', parent: ['contactIndex'], titleParam: ['id']
      }
    }
  ]
});

// Email template
routes.push({
  path     : '/email-template',
  name     : 'emailTemplate',
  component: () => import('@/views/layout/Main'),
  redirect : '/email-template/index',
  meta     : {title: 'Email'},

  children: [
    {
      path     : 'index',
      component: () => import('@/views/emailTemplate/index'),
      name     : 'emailTemplateIndex',
      meta     : {title: 'Email', parent: ['emailTemplateIndex']}
    },
    {
      path     : 'create',
      component: () => import('@/views/emailTemplate/form'),
      name     : 'emailTemplateCreate',
      meta     : {title: 'Создание Email шаблона', parent: ['emailTemplateIndex']}
    },
    {
      path     : 'update/:id',
      component: () => import('@/views/emailTemplate/form'),
      name     : 'emailTemplateUpdate',
      meta     : {
        title: 'Изменение Email шаблона', parent: ['emailTemplateIndex'], titleParam: ['id']
      }
    }
  ]
});

// Email template
routes.push({
  path     : '/email-template-common-settings',
  name     : 'emailTemplateCommonSettings',
  component: () => import('@/views/layout/Main'),
  redirect : '/email-template-common-settings',
  meta     : {title: 'Общие настройки сообщений'},

  children: [
    {
      path     : '',
      component: () => import('@/views/emailTemplate/commonSettings'),
      name     : 'emailTemplateCommonSettingsIndex',
      meta     : {title: 'Общие настройки сообщений', parent: ['emailTemplateIndex']}
    }
  ]
});
routes.push({
  path     : '/mailing-group',
  name     : 'mailingGroup',
  component: () => import('@/views/layout/Main'),
  redirect : '/mailing-group/index',
  meta     : {title: 'Группы подписки'},

  children: [
    {
      path     : 'index',
      component: () => import('@/views/mailingGroup/index.vue'),
      name     : 'mailingGroupIndex',
      meta     : {title: 'Группы подписки', parent: ['mailingGroupIndex']}
    },
    {
      path     : 'create',
      component: () => import('@/views/mailingGroup/form'),
      name     : 'mailingGroupCreate',
      meta     : {title: 'Создание группы подписки', parent: ['mailingGroupIndex']}
    },
    {
      path     : 'update/:id',
      component: () => import('@/views/mailingGroup/form'),
      name     : 'mailingGroupUpdate',
      meta     : {
        title: 'Изменение группы подписки', parent: ['mailingGroupIndex'], titleParam: ['id']
      }
    }
  ]
});
routes.push({
  path     : '/mailing-subscriber',
  name     : 'mailingSubscriber',
  component: () => import('@/views/layout/Main'),
  redirect : '/mailing-subscriber/index',
  meta     : {title: 'Подписчики'},

  children: [
    {
      path     : 'index',
      component: () => import('@/views/mailingSubscriber/index.vue'),
      name     : 'mailingSubscriberIndex',
      meta     : {title: 'Подписчики', parent: ['mailingSubscriberIndex']}
    },
    {
      path     : 'update/:id',
      component: () => import('@/views/mailingSubscriber/form.vue'),
      name     : 'mailingSubscriberUpdate',
      meta     : {
        title: 'Изменение подписчика', parent: ['mailingSubscriberIndex'], titleParam: ['id']
      }
    }
  ]
});
routes.push({
  path     : '/mailing',
  name     : 'mailing',
  component: () => import('@/views/layout/Main'),
  redirect : '/mailing/index',
  meta     : {title: 'Рассылки'},

  children: [
    {
      path     : 'index',
      component: () => import('@/views/mailing/index.vue'),
      name     : 'mailingIndex',
      meta     : {title: 'Рассылки', parent: ['mailingIndex']}
    },
    {
      path     : 'create',
      component: () => import('@/views/mailing/form'),
      name     : 'mailingCreate',
      meta     : {title: 'Создание рассылки', parent: ['mailingIndex']}
    },
    {
      path     : 'update/:id',
      component: () => import('@/views/mailing/form'),
      name     : 'mailingUpdate',
      meta     : {
        title: 'Изменение рассылки', parent: ['mailingIndex'], titleParam: ['id']
      }
    }
  ]
});
routes.push({
  path     : '/email-log',
  name     : 'emailLog',
  component: () => import('@/views/layout/Main'),
  redirect : '/email-log/index',
  meta     : {title: 'Лог почты'},

  children: [
    {
      path     : 'index',
      component: () => import('@/views/emailLog/index.vue'),
      name     : 'emailLogIndex',
      meta     : {title: 'Лог почты', parent: ['emailLogIndex']}
    }
  ]
});

// Магазин
routes.push({
  path     : '/product-category',
  component: () => import('@/views/layout/Main'),
  redirect : '/product-category/index',
  children : [
    {
      path     : 'index',
      component: () => import('@/views/productCategory/index'),
      name     : 'productCategoryIndex',
      meta     : {title: 'Категории товаров'}
    },
    {
      path     : 'create',
      component: () => import('@/views/productCategory/form'),
      name     : 'productCategoryCreate',
      meta     : {title: 'Создание категории товара', parent: ['productCategoryIndex']}
    },
    {
      path     : 'update/:id',
      component: () => import('@/views/productCategory/form'),
      name     : 'productCategoryUpdate',
      meta     : {
        title: 'Изменение категории товара', parent: ['productCategoryIndex'], titleParam: ['title']
      }
    }
  ]
});
routes.push({
  path     : '/currency',
  component: () => import('@/views/layout/Main'),
  redirect : '/currency/index',
  children : [
    {
      path     : 'index',
      component: () => import('@/views/currency/index'),
      name     : 'currencyIndex',
      meta     : {title: 'Валюта'}
    },
    {
      path     : 'create',
      component: () => import('@/views/currency/form'),
      name     : 'currencyCreate',
      meta     : {title: 'Создание валюты', parent: ['currencyIndex']}
    },
    {
      path     : 'update/:id',
      component: () => import('@/views/currency/form'),
      name     : 'currencyUpdate',
      meta     : {
        title: 'Изменение валюты', parent: ['currencyIndex'], titleParam: ['name_rus']
      }
    }
  ]
});
routes.push({
  path     : '/product',
  component: () => import('@/views/layout/Main'),
  redirect : '/product/index',
  children : [
    {
      path     : 'index',
      component: () => import('@/views/product/index'),
      name     : 'productIndex',
      meta     : {title: 'Товары'}
    },
    {
      path     : 'deactivated',
      component: () => import('@/views/product/index'),
      name     : 'productDeactivated',
      meta     : {title: 'Удаленные товары'}
    },
    {
      path     : 'create',
      component: () => import('@/views/product/form'),
      name     : 'productCreate',
      meta     : {title: 'Создание товара', parent: ['productIndex']}
    },
    {
      path     : 'update/:id',
      component: () => import('@/views/product/form'),
      name     : 'productUpdate',
      meta     : {
        title: 'Изменение товара', parent: ['productIndex'], titleParam: ['id']
      }
    }
  ]
});
routes.push({
  path     : '/order',
  component: () => import('@/views/layout/Main'),
  redirect : '/order/index',
  children : [
    {
      path     : 'index',
      component: () => import('@/views/order/index'),
      name     : 'orderIndex',
      meta     : {title: 'Заказы'}
    },
    {
      path     : 'deactivated',
      component: () => import('@/views/order/index'),
      name     : 'orderDeactivated',
      meta     : {title: 'Удаленные заказы', parent: ['orderDeactivated']}
    },
    {
      path     : 'create/:organizationId?',
      component: () => import('@/views/order/form'),
      name     : 'orderCreate',
      meta     : {title: 'Создание заказа', parent: ['orderIndex']}
    },
    {
      path     : 'update/:id',
      component: () => import('@/views/order/form'),
      name     : 'orderUpdate',
      meta     : {
        title: 'Изменение заказа', parent: ['orderIndex'], titleParam: ['id']
      }
    }
  ]
});
routes.push({
  path     : '/order-stand',
  component: () => import('@/views/layout/Main'),
  redirect : '/order-stand/index',
  children : [
    {
      path     : 'index',
      component: () => import('@/views/orderStand/index'),
      name     : 'orderStandIndex',
      meta     : {title: 'Стенды'}
    },
    // {
    //   path     : 'deactivated',
    //   component: () => import('@/views/orderStand/index'),
    //   name     : 'orderDeactivated',
    //   meta     : {title: 'Удаленные заказы', parent: ['orderDeactivated']}
    // },
    {
      path     : 'create/:organizationId?',
      component: () => import('@/views/orderStand/form'),
      name     : 'orderStandCreate',
      meta     : {title: 'Создание стенда', parent: ['orderStandIndex']}
    },
    {
      path     : 'update/:id',
      component: () => import('@/views/orderStand/form'),
      name     : 'orderStandUpdate',
      meta     : {
        title: 'Изменение стенда', parent: ['orderStandIndex'], titleParam: ['id']
      }
    }
  ]
});
routes.push({
  path     : '/document',
  component: () => import('@/views/layout/Main'),
  redirect : '/document/index',
  children : [
    {
      path     : 'index',
      component: () => import('@/views/document/index'),
      name     : 'documentIndex',
      meta     : {title: 'Документы'}
    },
    {
      path     : 'deactivated',
      component: () => import('@/views/document/index'),
      name     : 'documentDeactivated',
      meta     : {title: 'Удаленные документы', parent: ['documentDeactivated']}
    },
    {
      path     : 'upload',
      component: () => import('@/views/document/upload.vue'),
      name     : 'documentUpload',
      meta     : {title: 'Выгрузка документов', parent: ['documentUpload']}
    },
    {
      path     : 'create/:organizationId?',
      component: () => import('@/views/document/form'),
      name     : 'documentCreate',
      meta     : {title: 'Создание документа', parent: ['documentIndex']}
    },
    {
      path     : 'update/:id',
      component: () => import('@/views/document/form'),
      name     : 'documentUpdate',
      meta     : {
        title: 'Изменение документа', parent: ['documentIndex'], titleParam: ['id']
      }
    }
  ]
});
routes.push({
  path     : '/document-template',
  component: () => import('@/views/layout/Main'),
  redirect : '/document-template/index',
  children : [
    {
      path     : 'index',
      component: () => import('@/views/documentTemplate/index'),
      name     : 'documentTemplateIndex',
      meta     : {title: 'Шаблоны документов'}
    },
    {
      path     : 'create/:organizationId?',
      component: () => import('@/views/documentTemplate/form'),
      name     : 'documentTemplateCreate',
      meta     : {title: 'Создание шаблона документа', parent: ['documentTemplateIndex']}
    },
    {
      path     : 'update/:id',
      component: () => import('@/views/documentTemplate/form'),
      name     : 'documentTemplateUpdate',
      meta     : {
        title: 'Изменение шаблона документа', parent: ['documentTemplateIndex'], titleParam: ['id']
      }
    }
  ]
});
routes.push({
  path     : '/document-numbering',
  component: () => import('@/views/layout/Main'),
  redirect : '/document-numbering/index',
  children : [
    {
      path     : 'index',
      component: () => import('@/views/documentNumbering/index'),
      name     : 'documentNumberingIndex',
      meta     : {title: 'Нумерации документов'}
    },
    {
      path     : 'create/:organizationId?',
      component: () => import('@/views/documentNumbering/form'),
      name     : 'documentNumberingCreate',
      meta     : {title: 'Создание нумерации документа', parent: ['documentNumberingIndex']}
    },
    {
      path     : 'update/:id',
      component: () => import('@/views/documentNumbering/form'),
      name     : 'documentNumberingUpdate',
      meta     : {
        title: 'Изменение нумерации документа', parent: ['documentNumberingIndex'], titleParam: ['id']
      }
    }
  ]
});
routes.push({
  path     : '/upload-for-1c',
  component: () => import('@/views/layout/Main'),
  redirect : '/upload-for-1c/index',
  children : [
    {
      path     : 'index',
      component: () => import('@/views/uploadFor1c/form'),
      name     : 'uploadFor1cForm',
      meta     : {title: 'Выгрузка для 1С'}
    },
  ]
});
routes.push({
  path     : '/analytics',
  component: () => import('@/views/layout/Main'),
  redirect : '/analytics/index',
  children : [
    {
      path     : 'index',
      component: () => import('@/views/analytics/index'),
      name     : 'analyticsIndex',
      meta     : {title: 'Аналитика'}
    },
  ]
});
routes.push({
  path     : '/badge-template',
  component: () => import('@/views/layout/Main'),
  redirect : '/badge-template/index',
  children : [
    {
      path     : 'index',
      component: () => import('@/views/badgeTemplate/index'),
      name     : 'badgeTemplateIndex',
      meta     : {title: 'Шаблоны бейджей'}
    },
    {
      path     : 'create',
      component: () => import('@/views/badgeTemplate/form'),
      name     : 'badgeTemplateCreate',
      meta     : {title: 'Создание шаблона бейджа', parent: ['badgeTemplateIndex']}
    },
    {
      path     : 'update/:id',
      component: () => import('@/views/badgeTemplate/form'),
      name     : 'badgeTemplateUpdate',
      meta     : {
        title: 'Изменение шаблона бейджа', parent: ['badgeTemplateIndex'], titleParam: ['id']
      }
    }
  ]
});
routes.push({
  path     : '/badge-delegation',
  component: () => import('@/views/layout/Main'),
  redirect : '/badge-delegation/index',
  children : [
    {
      path     : 'index',
      component: () => import('@/views/badgeDelegation/index'),
      name     : 'badgeDelegationIndex',
      meta     : {title: 'Квоты'}
    },
    {
      path     : 'create',
      component: () => import('@/views/badgeDelegation/form'),
      name     : 'badgeDelegationCreate',
      meta     : {title: 'Создание передачи квоты', parent: ['badgeDelegationIndex']}
    },
    {
      path     : 'update/:id',
      component: () => import('@/views/badgeDelegation/form'),
      name     : 'badgeDelegationUpdate',
      meta     : {
        title: 'Изменение передачи квоты', parent: ['badgeDelegationIndex'], titleParam: ['id']
      }
    }
  ]
});
routes.push({
  path     : '/promo-code',
  component: () => import('@/views/layout/Main'),
  redirect : '/promo-code/index',
  children : [
    {
      path     : 'index',
      component: () => import('@/views/promoCode/index'),
      name     : 'promoCodeIndex',
      meta     : {title: 'Промокоды'}
    },
    {
      path     : 'create',
      component: () => import('@/views/promoCode/form'),
      name     : 'promoCodeCreate',
      meta     : {title: 'Создание промокода', parent: ['promoCodeIndex']}
    },
    {
      path     : 'update/:id',
      component: () => import('@/views/promoCode/form'),
      name     : 'promoCodeUpdate',
      meta     : {
        title: 'Изменение промокода', parent: ['promoCodeIndex'], titleParam: ['id']
      }
    }
  ]
});
routes.push({
  path     : '/validator',
  component: () => import('@/views/layout/Main'),
  redirect : '/validator/index',
  children : [
    {
      path     : 'index',
      component: () => import('@/views/validator/index'),
      name     : 'validatorIndex',
      meta     : {title: 'Валидаторы'}
    },
    {
      path     : 'create',
      component: () => import('@/views/validator/form'),
      name     : 'validatorCreate',
      meta     : {title: 'Создание валидатора', parent: ['validatorIndex']}
    },
    {
      path     : 'update/:id',
      component: () => import('@/views/validator/form'),
      name     : 'validatorUpdate',
      meta     : {
        title: 'Изменение валидатора', parent: ['validatorIndex'], titleParam: ['id']
      }
    }
  ]
});
routes.push({
  path     : '/event-venue',
  component: () => import('@/views/layout/Main'),
  redirect : '/event-venue/index',
  children : [
    {
      path     : 'index',
      component: () => import('@/views/eventVenue/index'),
      name     : 'eventVenueIndex',
      meta     : {title: 'Места'}
    },
    {
      path     : 'create',
      component: () => import('@/views/eventVenue/form'),
      name     : 'eventVenueCreate',
      meta     : {title: 'Создание места', parent: ['eventVenueIndex']}
    },
    {
      path     : 'update/:id',
      component: () => import('@/views/eventVenue/form'),
      name     : 'eventVenueUpdate',
      meta     : {
        title: 'Изменение места', parent: ['eventVenueIndex'], titleParam: ['id']
      }
    }
  ]
});
routes.push({
  path     : '/event-speaker',
  component: () => import('@/views/layout/Main'),
  redirect : '/event-speaker/index',
  children : [
    {
      path     : 'index',
      component: () => import('@/views/eventSpeaker/index'),
      name     : 'eventSpeakerIndex',
      meta     : {title: 'Спикеры'}
    },
    {
      path     : 'create',
      component: () => import('@/views/eventSpeaker/form'),
      name     : 'eventSpeakerCreate',
      meta     : {title: 'Создание Спикера', parent: ['eventSpeakerIndex']}
    },
    {
      path     : 'update/:id',
      component: () => import('@/views/eventSpeaker/form'),
      name     : 'eventSpeakerUpdate',
      meta     : {
        title: 'Изменение Спикера', parent: ['eventSpeakerIndex'], titleParam: ['id']
      }
    }
  ]
});
routes.push({
  path     : '/event-program',
  component: () => import('@/views/layout/Main'),
  redirect : '/event-program/index',
  children : [
    {
      path     : 'index',
      component: () => import('@/views/eventProgram/index'),
      name     : 'eventProgramIndex',
      meta     : {title: 'Программы'}
    },
    {
      path     : 'create',
      component: () => import('@/views/eventProgram/form'),
      name     : 'eventProgramCreate',
      meta     : {title: 'Создание Программы', parent: ['eventProgramIndex']}
    },
    {
      path     : 'update/:id',
      component: () => import('@/views/eventProgram/form'),
      name     : 'eventProgramUpdate',
      meta     : {
        title: 'Изменение Программы', parent: ['eventProgramIndex'], titleParam: ['id']
      }
    }
  ]
});
routes.push({
  path     : '/event-moderator',
  component: () => import('@/views/layout/Main'),
  redirect : '/event-moderator/index',
  children : [
    {
      path     : 'index',
      component: () => import('@/views/eventModerator/index'),
      name     : 'eventModeratorIndex',
      meta     : {title: 'Модераторы'}
    },
    {
      path     : 'create',
      component: () => import('@/views/eventModerator/form'),
      name     : 'eventModeratorCreate',
      meta     : {title: 'Создание Модератора', parent: ['eventModeratorIndex']}
    },
    {
      path     : 'update/:id',
      component: () => import('@/views/eventModerator/form'),
      name     : 'eventModeratorUpdate',
      meta     : {
        title: 'Изменение Модератора', parent: ['eventModeratorIndex'], titleParam: ['id']
      }
    }
  ]
});
routes.push({
  path     : '/event-category',
  component: () => import('@/views/layout/Main'),
  redirect : '/event-category/index',
  children : [
    {
      path     : 'index',
      component: () => import('@/views/eventCategory/index'),
      name     : 'eventCategoryIndex',
      meta     : {title: 'Темы'}
    },
    {
      path     : 'create',
      component: () => import('@/views/eventCategory/form'),
      name     : 'eventCategoryCreate',
      meta     : {title: 'Создание темы', parent: ['eventCategoryIndex']}
    },
    {
      path     : 'update/:id',
      component: () => import('@/views/eventCategory/form'),
      name     : 'eventCategoryUpdate',
      meta     : {
        title: 'Изменение темы', parent: ['eventCategoryIndex'], titleParam: ['id']
      }
    }
  ]
});
routes.push({
  path     : '/event-type',
  component: () => import('@/views/layout/Main'),
  redirect : '/event-type/index',
  children : [
    {
      path     : 'index',
      component: () => import('@/views/eventType/index'),
      name     : 'eventTypeIndex',
      meta     : {title: 'Программы'}
    },
    {
      path     : 'create',
      component: () => import('@/views/eventType/form'),
      name     : 'eventTypeCreate',
      meta     : {title: 'Создание программы', parent: ['eventTypeIndex']}
    },
    {
      path     : 'update/:id',
      component: () => import('@/views/eventType/form'),
      name     : 'eventTypeUpdate',
      meta     : {
        title: 'Изменение программы', parent: ['eventTypeIndex'], titleParam: ['id']
      }
    }
  ]
});
routes.push({
  path     : '/business-program',
  component: () => import('@/views/layout/Main'),
  redirect : '/business-program/upload',
  children : [
    {
      path     : 'upload',
      component: () => import('@/views/businessProgram/upload.vue'),
      name     : 'businessProgramUpload',
      meta     : {title: 'Загрузка PDF программы'}
    },
  ]
});
routes.push({
  path     : '/event',
  component: () => import('@/views/layout/Main'),
  redirect : '/event/index',
  children : [
    {
      path     : 'index',
      component: () => import('@/views/event/index'),
      name     : 'eventIndex',
      meta     : {title: 'Мероприятия'}
    },
    {
      path     : 'create',
      component: () => import('@/views/event/form'),
      name     : 'eventCreate',
      meta     : {title: 'Создание Мероприятия', parent: ['eventIndex']}
    },
    {
      path     : 'update/:id',
      component: () => import('@/views/event/form'),
      name     : 'eventUpdate',
      meta     : {
        title: 'Изменение Мероприятия', parent: ['eventIndex'], titleParam: ['id']
      }
    }
  ]
});
routes.push({
  path     : '/validation-log',
  component: () => import('@/views/layout/Main'),
  redirect : '/validation-log/index',
  children : [
    {
      path     : 'index',
      component: () => import('@/views/validationLog/index'),
      name     : 'validationLogIndex',
      meta     : {title: 'Лог валидаций'}
    },
  ]
});
routes.push({
  path     : '/audit-log',
  component: () => import('@/views/layout/Main'),
  redirect : '/audit-log/index',
  children : [
    {
      path     : 'index',
      component: () => import('@/views/auditLog/index'),
      name     : 'auditLogIndex',
      meta     : {title: 'Лог изменений'}
    },
  ]
});
routes.push({
  path     : '/conf-company-functional',
  component: () => import('@/views/layout/Main'),
  redirect : '/conf-company-functional/index',
  children : [
    {
      path     : 'index',
      component: () => import('@/views/confCompanyFunctional/index'),
      name     : 'confCompanyFunctionalIndex',
      meta     : {title: 'Функционал компании/организации'}
    },
    {
      path     : 'create',
      component: () => import('@/views/confCompanyFunctional/form'),
      name     : 'confCompanyFunctionalCreate',
      meta     : {title: 'Создание Функционал компании/организации', parent: ['confCompanyFunctionalIndex']}
    },
    {
      path     : 'update/:id',
      component: () => import('@/views/confCompanyFunctional/form'),
      name     : 'confCompanyFunctionalUpdate',
      meta     : {
        title: 'Изменение Функционал компании/организации', parent: ['confCompanyFunctionalIndex'], titleParam: ['id']
      }
    }
  ]
});
routes.push({
  path     : '/conf-company-position',
  component: () => import('@/views/layout/Main'),
  redirect : '/conf-company-position/index',
  children : [
    {
      path     : 'index',
      component: () => import('@/views/confCompanyPosition/index'),
      name     : 'confCompanyPositionIndex',
      meta     : {title: 'Должность в компании/организации'}
    },
    {
      path     : 'create',
      component: () => import('@/views/confCompanyPosition/form'),
      name     : 'confCompanyPositionCreate',
      meta     : {title: 'Создание Должность в компании/организации', parent: ['confCompanyPositionIndex']}
    },
    {
      path     : 'update/:id',
      component: () => import('@/views/confCompanyPosition/form'),
      name     : 'confCompanyPositionUpdate',
      meta     : {
        title: 'Изменение Должность в компании/организации', parent: ['confCompanyPositionIndex'], titleParam: ['id']
      }
    }
  ]
});
routes.push({
  path     : '/conf-purpose-of-visiting',
  component: () => import('@/views/layout/Main'),
  redirect : '/conf-purpose-of-visiting/index',
  children : [
    {
      path     : 'index',
      component: () => import('@/views/confPurposeOfVisiting/index'),
      name     : 'confPurposeOfVisitingIndex',
      meta     : {title: 'Цель посещения выставки'}
    },
    {
      path     : 'create',
      component: () => import('@/views/confPurposeOfVisiting/form'),
      name     : 'confPurposeOfVisitingCreate',
      meta     : {title: 'Создание Цель посещения выставки', parent: ['confPurposeOfVisitingIndex']}
    },
    {
      path     : 'update/:id',
      component: () => import('@/views/confPurposeOfVisiting/form'),
      name     : 'confPurposeOfVisitingUpdate',
      meta     : {
        title: 'Изменение Цель посещения выставки', parent: ['confPurposeOfVisitingIndex'], titleParam: ['id']
      }
    }
  ]
});
routes.push({
  path     : '/conf-visited-exhibition-before',
  component: () => import('@/views/layout/Main'),
  redirect : '/conf-visited-exhibition-before/index',
  children : [
    {
      path     : 'index',
      component: () => import('@/views/confVisitedExhibitionBefore/index'),
      name     : 'confVisitedExhibitionBeforeIndex',
      meta     : {title: 'Посещали выставку ранее'}
    },
    {
      path     : 'create',
      component: () => import('@/views/confVisitedExhibitionBefore/form'),
      name     : 'confVisitedExhibitionBeforeCreate',
      meta     : {title: 'Создание Посещали выставку ранее', parent: ['confVisitedExhibitionBeforeIndex']}
    },
    {
      path     : 'update/:id',
      component: () => import('@/views/confVisitedExhibitionBefore/form'),
      name     : 'confVisitedExhibitionBeforeUpdate',
      meta     : {
        title: 'Изменение Посещали выставку ранее', parent: ['confVisitedExhibitionBeforeIndex'], titleParam: ['id']
      }
    }
  ]
});
routes.push({
  path     : '/bank-terminal',
  component: () => import('@/views/layout/Main'),
  redirect : '/bank-terminal/index',
  children : [
    {
      path     : 'index',
      component: () => import('@/views/bankTerminal/index'),
      name     : 'bankTerminalIndex',
      meta     : {title: 'Интернет-эквайринг'}
    },
    {
      path     : 'create',
      component: () => import('@/views/bankTerminal/form'),
      name     : 'bankTerminalCreate',
      meta     : {title: 'Создание терминала для интернет-эквайринга', parent: ['bankTerminalIndex']}
    },
    {
      path     : 'update/:id',
      component: () => import('@/views/bankTerminal/form'),
      name     : 'bankTerminalUpdate',
      meta     : {
        title: 'Изменение терминала для интернет-эквайринга', parent: ['bankTerminalIndex'], titleParam: ['id']
      }
    }
  ]
});
routes.push({
  path     : '/menu-editor',
  component: () => import('@/views/layout/Main'),
  redirect : '/menu-editor/index',
  children : [
    {
      path     : 'index',
      component: () => import('@/views/menuEditor/index.vue'),
      name     : 'menuEditorIndex',
      meta     : {title: 'Редактор меню Личного кабинета'}
    },
  ]
});
routes.push({
  path     : '/1c-api-settings',
  component: () => import('@/views/layout/Main'),
  redirect : '/1c-api-settings/index',
  children : [
    {
      path     : 'index',
      component: () => import('@/views/1cApiSettings/index.vue'),
      name     : '1cApiSettingsIndex',
      meta     : {title: 'API для 1C'}
    },
  ]
});

const Router = new VueRouter({
  mode                : 'history',
  routes,
  linkExactActiveClass: 'active',
  parseQuery(query) {
    let params;
    try {
      params = qs.parse(query, {skipNulls: true, format: 'RFC1738'});
    }
    catch (e) {

    }
    return params;
  },
  stringifyQuery(query) {
    let result;

    try {
      result = qs.stringify(query, {skipNulls: true, format: 'RFC1738'});
    }
    catch (e) {

    }

    return result ? ('?' + result) : '';
  }
});

Router.beforeEach((to, from, next) => {

  const store = Router.app.$store;

  if (to.path != from.path) {
    if (!to.meta || !to.meta.disableLoader) {
      Router.app.$store.commit('SHOW_LOADER');
    }
  }

  if (to.path !== '/login' && (!store.getters.token || !store.getters.user)) {
    window.console.log('Not authenticated');
    store.dispatch('setReferrer', to.path);
    next({path: '/login'});
  }
  else if (to.path === '/' || (to.path === '/login' && store.getters.token && store.getters.user)) {
    if (store.getters.referrer) {
      store.dispatch('clearReferrer');
      next({path: store.getters.referrer});
    }
    else {
      next({path: '/dashboard'});
    }
  }
  else {
    next();
  }

  let title = 'Панель администрирования';
  document.title = to?.meta?.title ? `${to?.meta?.title} | ${title}` : title;
});

export default Router;
