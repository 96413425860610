import * as API from '@/helpers/request';

const controllerPath = 'price';

export function getItems(filter, perPage, page, sort) {
  return API.getItems(controllerPath, filter, perPage, page, sort);
}

export function getItemsList(data) {
  return API.getItemsList(controllerPath, data);
}

export async function getItem(data) {
  try {
    let price = await API.getItem(controllerPath, data)

    price.currency = price.currency.id
    price.product = price.product.id

    return price
  }
  catch (e) {
    console.log(e)
  }
}

export function createItem(data) {
  return API.createItem(controllerPath, data);
}

export function updateItem(id, data) {
  return API.updateItem(controllerPath, id, data);
}

export function deleteItem(data) {
  return API.deleteItem(controllerPath, data);
}