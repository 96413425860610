import Vue from 'vue';

const getters = {
  sidebar         : state => state.app.sidebar,
  orderTotalSum   : state => state.order.totalSum,
  orderTotalSumVat: state => state.order.totalSumVat,
  breadcrumb      : state => state.app.breadcrumbVisible,
  showGlobalScroll: state => state.app.showGlobalScroll,
  footer          : state => state.app.footerVisible,
  formIsChanged   : state => state.app.formIsChanged,
  showLoader      : state => state.app.showLoader,
  language        : state => state.app.language,
  constants       : state => state.app.constants,
  token           : state => state.user.token,
  user            : state => state.user.user,
  roles           : state => state.user.roles,
  userId          : state => state.user.id,
  appId           : state => state.app.constants['Global::APP_ADMIN'],
  isSuperadmin    : state => Boolean(state.user.superadmin),
  errorLogs       : state => state.errorLog.logs,
  checkPermission : state => (perm, isNot = false, positiveSuperadmin = false) => {
    if (!perm || (perm instanceof String && typeof perm !== 'string')) {
      return !!isNot;
    }

    if (state.user.superadmin === true) {
      return positiveSuperadmin ? true : !isNot;
    }

    if (!Array.isArray(perm)) {
      perm = [perm];
    }

    if (perm[0].toUpperCase() === 'OR') {
      perm.shift();

      return perm.some(value => {
        let res = state.user.permissions.includes(value);
        return isNot ? !res : res;
      });
    }

    return perm.every(value => {
      let res = state.user.permissions.includes(value);
      return isNot ? !res : res;
    });
  },
  checkRole       : state => role => {
    if (!role || (role instanceof String && typeof role !== 'string')) {
      return false;
    }

    if (state.user.superadmin === true) {
      return true;
    }

    if (!Array.isArray(role)) {
      role = [role];
    }

    if (role[0].toUpperCase() === 'OR') {
      role.shift();

      return role.some(value => {
        return state.user.roles.includes(value);
      });
    }

    return role.every(value => {
      return state.user.roles.includes(value);
    });
  },

  // Scheme edtitor getters
  selectedTool             : state => state.schemeEditor.selectedTool,
  showGrid                 : state => state.schemeEditor.showGrid,
  showToaster              : state => state.schemeEditor.showToaster,
  showBorder               : state => state.schemeEditor.showBorder,
  showNumber               : state => state.schemeEditor.showNumber,
  selectedSeats            : state => state.schemeEditor.selectedSeats,
  action                   : state => state.schemeEditor.action,
  newElement               : state => state.schemeEditor.newElement,
  selectedElements         : state => state.schemeEditor.selectedElements,
  selectedElementsEdited   : state => state.schemeEditor.selectedElementsEdited,
  info                     : state => state.schemeEditor.info,
  preSelectedSeats         : state => state.schemeEditor.preSelectedSeats,
  selectedSeatsApproved    : state => state.schemeEditor.selectedSeatsApproved,
  showRowNumber            : state => state.schemeEditor.showRowNumber,
  isAbonementEnable        : state => state.app.constants
                                      && state.app.constants['Global::ACTIVE_MODULES'].includes(state.app.constants['Global::MODULE__ABONEMENT']),
  isEventEnable            : state => state.app.constants
                                      && state.app.constants['Global::ACTIVE_MODULES'].includes(state.app.constants['Global::MODULE__EVENT']),
  isExcursionEnable        : state => state.app.constants
                                      && state.app.constants['Global::ACTIVE_MODULES'].includes(state.app.constants['Global::MODULE__EXCURSION_DESK']),
  isServiceEnable          : state => state.app.constants
                                      && state.app.constants['Global::ACTIVE_MODULES'].includes(state.app.constants['Global::MODULE__SERVICE']),
  isPacsInfomaticaEnable   : state => state.app.constants
                                      && state.app.constants['Global::ACTIVE_MODULES'].includes(state.app.constants['Global::MODULE__PACS_INFOMATICA']),
  checkLimitByTicketPersons: () => Vue.prototype.$settings.ALL.ALL.check_limit_by_ticket_persons
};

export default getters;
