import * as API          from '@/helpers/request';
import { AxiosResponse } from 'axios';

/**
 * Страны
 *
 * Путь к контроллеру
 *
 * @type {string}
 */
const controllerPath = 'section';

/**
 * Список всех стран по странично
 *
 * @param filter
 * @param perPage
 * @param page
 * @param sort
 * @returns {Promise<AxiosResponse<T>>}
 */
export function getItems(filter, perPage, page, sort) {
	return API.getItems(controllerPath, filter, perPage, page, sort);
}

/**
 * Список всех стран с укороченной информацией [id, title]
 *
 * @returns {Promise<AxiosResponse<T>>}
 */
export function getItemsList(data) {
	return API.getItemsList(controllerPath, data);
}

/**
 * Информация о стране
 *
 * @param data
 * @returns {Promise<AxiosResponse<T>>}
 */
export function getItem(data) {
	return API.getItem(controllerPath, data);
}

/**
 * Создание страны
 *
 * @param data
 * @returns {Promise<AxiosResponse<T>>}
 */
export function createItem(data) {
	return API.createItem(controllerPath, data);
}

/**
 * Изменение страны
 *
 * @param id
 * @param data
 * @returns {Promise<AxiosResponse<T>>}
 */
export function updateItem(id, data) {
	return API.updateItem(controllerPath, id, data);
}

/**
 * Удаление страны
 *
 * @param id
 * @returns {Promise<AxiosResponse<T>>}
 */
export function deleteItem(id) {
	return API.deleteItem(controllerPath, id);
}
