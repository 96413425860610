import * as API from '@/helpers/request';
import _ from "lodash";

const controllerPath = 'order-stand';

export function getItems(filter, perPage, page, sort) {
  return API.getItems(controllerPath, filter, perPage, page, sort);
}

export function getItemsList(data) {
  return API.getItemsList(controllerPath, data);
}

export async function getItem(data) {
  try {
    let orderStand = await API.getItem(controllerPath, data)

    // let orderIds = []
    //
    // _.forEach(orderStand.orders, (order) => {
    //   orderIds.push(order.id)
    // })
    //
    // orderStand.orders = orderIds

    orderStand.exposition_place = orderStand.exposition_place.id

    if (orderStand.close_area_type) {
      orderStand.close_area_type = orderStand.close_area_type.id
    }

    if (orderStand.area_building_type) {
      orderStand.area_building_type = orderStand.area_building_type.id
    }

    return orderStand
  }
  catch (e) {
    console.log(e)
  }
}

export function createItem(data) {
  return API.createItem(controllerPath, data);
}

export function updateItem(id, data) {
  return API.updateItem(controllerPath, id, data);
}

export function deleteItem(data) {
  return API.deleteItem(controllerPath, data);
}

export function getByOrganization(organizationId) {
  return API.request.post(`${controllerPath}/get-by-organization`, {organizationId});
}

export function getExpositionPlaceList() {
  return API.getItemsList('exposition-place', null);
}

export function getCloseAreaTypeList() {
  return API.getItemsList('close-area-type', null);
}

export function getAreaBuildingTypeList(data) {
  return API.getItemsList('area-building-type', data);
}