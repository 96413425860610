import Vue from 'vue';
import Vuex from 'vuex';
import getters from './getters';
// Модули
import app from './modules/app';
import user from './modules/user';
import schemeEditor from './modules/schemeEditor';
import errorLog from './modules/errorLog';
import cart from 'Global/store/cart';
import order from './modules/order';
// Тесты
import initVuexTestMethods from 'Global/store/tests';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    app,
    user,
    errorLog,
    schemeEditor,
    cart,
    order
  },
  getters
});

initVuexTestMethods(store);

export default store;
