import * as API from '@/helpers/request';
import _ from "lodash";

const controllerPath = 'organization';

export function getItems(filter, perPage, page, sort) {
  return API.getItems(controllerPath, filter, perPage, page, sort);
}

export function getItemsList(data) {
  return API.getItemsList(controllerPath, data);
}

export async function getItem(data) {
  try {
    let organization = await API.getItem(controllerPath, data)

    organization.managers = _.map(organization.managers, 'id')
    organization.participation_categories = _.map(organization.participation_categories, 'id')

    if (organization.legal_address_country) {
      organization.legal_address_country = organization.legal_address_country.id
    }

    if (organization.postal_address_country) {
      organization.postal_address_country = organization.postal_address_country.id
    }

    if (organization.alfa_contact) {
      organization.alfa_contact = organization.alfa_contact.id
    }

    return organization
  }
  catch (e) {
    console.error(e)
  }
}

export function createItem(data) {
  return API.createItem(controllerPath, data);
}

export function updateItem(id, data) {
  delete data.payer_companies[0].id

  return API.updateItem(controllerPath, id, data);
}

export function deleteItem(id) {
  return API.deleteItem(controllerPath, id);
}

export function getParticipationCategoriesList() {
  return API.request.post(controllerPath + '/participation-categories-list');
}

export function getTypesList() {
  return API.request.post(controllerPath + '/types-list');
}

export function getManagersList(data) {
  return API.request.post(controllerPath + '/managers-list', data);
}

export function getFreeExhibitorsList(data) {
  const id = data.condition.id

  return API.request.post(`${controllerPath}/${id}/free-exhibitors-list`);
}

export function addExhibitors(id, exhibitors) {
  return API.request.patch(`${controllerPath}/${id}/add-exhibitors`, exhibitors);
}

export function getFreeContactsList(data) {
  const id = data.condition.id

  return API.request.post(`${controllerPath}/${id}/free-contacts-list`);
}

export function addContacts(id, contacts) {
  return API.request.patch(`${controllerPath}/${id}/add-contacts`, contacts);
}

export function addStands(id, stands) {
  return API.request.post(`${controllerPath}/${id}/add-stands`, stands);
}

export function removeStand(id, standId) {
  return API.request.post(`${controllerPath}/${id}/remove-stand`, {standId});
}