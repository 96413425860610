import * as API from '@/helpers/request';

const controllerPath = 'setting';

export function getItems(filter, perPage, page, sort) {
  return API.getItems(controllerPath, filter, perPage, page, sort);
}

export function getItem(data) {
  return API.getItem(controllerPath, data)
}

export function updateItem(id, data) {
  return API.updateItem(controllerPath, id, data);
}

export function saveValue(id, value) {
  return API.request.post(`${controllerPath}/${id}/save-value`, {value});
}
