import * as API from '@/helpers/request';

const controllerPath = 'audit-log';

export function getFirstAndLastLogRecord(entity, entityId) {
  return API.request.post(`${controllerPath}/get-first-and-last-log-record`, {entity, entityId});
}

export function getItems(filter, perPage, page, sort) {
  return API.getItems(controllerPath, filter, perPage, page, sort);
}

export function getItemsList(data) {
  return API.getItemsList(controllerPath, data);
}

export function getItem(data) {
  return API.getItem(controllerPath, data);
}