import * as common                                             from '@/api/common';
import { getAuth, getLocalStorageParam, setLocalStorageParam } from 'Global/helpers/localStorage';
import { setAll as setAllSettings }                            from 'Global/helpers/settings/SettingsStore';
import Vue                                                     from 'vue';

const app = {
	state     : {
		sidebar           : {
			opened : Boolean(+getLocalStorageParam('sidebarStatus', true)),
			menu   : []
		},
		formIsChanged     : false,
		language          : getLocalStorageParam('language', 'ru'),
		referrer          : false,
		lastUrl           : '/',
		constants         : [],
		showLoader        : true,
		showGlobalScroll  : true,
		breadcrumbVisible : true,
		footerVisible     : true,
		cashierTab        : null
	},
	mutations : {
		TOGGLE_SIDEBAR       : state => {
			state.sidebar.opened = !state.sidebar.opened;
			setLocalStorageParam('sidebarStatus', +state.sidebar.opened);
		},
		SHOW_BREADCRUMB      : state => {
			state.breadcrumbVisible = true;
		},
		HIDE_BREADCRUMB      : state => {
			state.breadcrumbVisible = false;
		},
		SHOW_FOOTER          : state => {
			state.footerVisible = true;
		},
		HIDE_FOOTER          : state => {
			state.footerVisible = false;
		},
		CLOSE_SIDEBAR        : (state) => {
			setLocalStorageParam('sidebarStatus', '1');
			state.sidebar.opened = false;
		},
		SET_LANGUAGE         : (state, language) => {
			state.language = language;
			setLocalStorageParam('language', language);
		},
		SET_REFERRER         : (state, referrer) => {
			state.referrer = referrer;
		},
		SET_SIDEBAR_MENU     : (state, menu) => {
			state.sidebar.menu = menu;
		},
		SET_SETTINGS         : (state, data) => {
			// Запись настроек в новое API
			setAllSettings(data);
		},
		SET_CONSTANTS        : (state, data) => {
			state.constants = data;
		},
		SET_ADMIN_FORM_STATE : (state, data) => {
			/**
			 * @see vue/apps/admin/src/components/AdminForm/index.vue, methods : { getItem() {......}
			 **/
			state.formIsChanged = data;
		},
		SHOW_LOADER          : (state) => {
			state.showLoader = true;
		},
		HIDE_LOADER          : (state) => {
			state.showLoader = false;
		},
		SHOW_GLOBAL_SCROLL   : (state) => {
			state.showGlobalScroll = true;
		},
		HIDE_GLOBAL_SCROLL   : (state) => {
			state.showGlobalScroll = false;
		},
		SET_CASHIER_TAB      : (state, data) => {
			state.cashierTab = data;
		}
	},
	actions   : {
		/**
		 * Переключение состояния бокового меню
		 *
		 * @param commit
		 * @param state
		 */
		toggleSideBar({ commit, state }) {
			commit('TOGGLE_SIDEBAR');
		},
		/**
		 * Закрытие бокового меню
		 *
		 * @param commit
		 */
		closeSideBar({ commit }) {
			commit('CLOSE_SIDEBAR');
		},
		/**
		 * Установка языка
		 *
		 * @param commit
		 * @param language
		 */
		setLanguage({ commit }, language) {
			commit('SET_LANGUAGE', language);
		},
		/**
		 * Установка ссылки для последующего перехода по ней, например, после авторизации
		 *
		 * @param commit
		 * @param referrer
		 */
		setReferrer({ commit }, referrer) {
			commit('SET_REFERRER', referrer);
		},
		/**
		 * Установка состояние формы
		 *
		 * @param commit
		 * @param state
		 */
		setAdminFormChanged({ commit }, state) {
			commit('SET_ADMIN_FORM_STATE', state);
		},
		/**
		 * Отчистка ссылки перехода
		 *
		 * @param commit
		 */
		clearReferrer({ commit }) {
			commit('SET_REFERRER', false);
		},
		/**
		 * Получения массива бокового меню
		 *
		 * @param commit
		 * @param state
		 * @returns {Promise<any>}
		 */
		getSidebarMenu({ commit, state }) {
			return new Promise((resolve, reject) => {
				if (state.sidebar.menu && state.sidebar.menu.length > 0) {
					resolve();
					return;
				}

				common.getSidebarMenu()
				      .then(data => {
					      commit('SET_SIDEBAR_MENU', data);
					      resolve();
				      })
				      .catch(error => {
					      reject(error);
				      });
			});
		},
	}
};

export default app;
