import * as common from './common';
import * as country from './country';
import * as setting from './setting';
import * as user from './user';
import * as userLog from './userLog';
import * as organization from './organization';
import * as exhibitor from './exhibitor';
import * as exhibitorSlide from './exhibitorSlide';
import * as section from './section';
import * as participationCategory from './participationCategory';
import * as status from './status';
import * as contact from './contact';
import * as emailTemplate from './emailTemplate';
import * as mailing from './mailing';
import * as mailingGroup from './mailingGroup';
import * as mailingSubscriber from './mailingSubscriber';
import * as emailLog from './emailLog';
import * as mailer from './mailer';
import * as unit from './unit';
import * as productCategory from './productCategory';
import * as currency from './currency';
import * as product from './product';
import * as price from './price';
import * as order from './order';
import * as orderItem from './orderItem';
import * as orderStand from './orderStand';
import * as auditLog from './auditLog';
import * as document from './document';
import * as documentTemplate from './documentTemplate';
import * as documentNumbering from './documentNumbering';
import * as documentContent from './documentContent';
import * as report from './report';
import * as badgeTemplate from './badgeTemplate';
import * as badgeDelegation from './badgeDelegation';
import * as validator from './validator';
import * as validationLog from './validationLog';
import * as promoCode from './promoCode';
import * as event from './event';
import * as eventCategory from './eventCategory';
import * as eventType from './eventType';
import * as eventModerator from './eventModerator';
import * as eventPresentation from './eventPresentation';
import * as eventProgram from './eventProgram';
import * as eventSpeaker from './eventSpeaker';
import * as eventVenue from './eventVenue';
import * as confCompanyPosition from './confCompanyPosition';
import * as confCompanyFunctional from './confCompanyFunctional';
import * as confPurposeOfVisiting from './confPurposeOfVisiting';
import * as confVisitedExhibitionBefore from './confVisitedExhibitionBefore';
import * as bankTerminal from './bankTerminal';

const API = {
  common,
  country,
  setting,
  user,
  userLog,
  organization,
  exhibitor,
  exhibitorSlide,
  section,
  participationCategory,
  status,
  contact,
  emailTemplate,
  mailing,
  mailingGroup,
  mailingSubscriber,
  emailLog,
  mailer,
  unit,
  productCategory,
  currency,
  product,
  price,
  order,
  orderItem,
  orderStand,
  auditLog,
  document,
  documentTemplate,
  documentNumbering,
  documentContent,
  report,
  badgeTemplate,
  badgeDelegation,
  promoCode,
  validator,
  validationLog,
  event,
  eventCategory,
  eventType,
  eventModerator,
  eventPresentation,
  eventProgram,
  eventSpeaker,
  eventVenue,
  confCompanyPosition,
  confCompanyFunctional,
  confPurposeOfVisiting,
  confVisitedExhibitionBefore,
  bankTerminal,
};

export default {
  install(Vue) {
    Vue.$API = API;
    Object.defineProperties(Vue.prototype, {
      $API: {
        get() {
          return API;
        }
      }
    });
  }
};
