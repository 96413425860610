import * as API from '@/helpers/request';

const controllerPath = 'promo-code';

export function getItems(filter, perPage, page, sort) {
  return API.getItems(controllerPath, filter, perPage, page, sort);
}

export function getItemsList(data) {
  return API.getItemsList(controllerPath, data);
}

export async function getItem(data) {
  try {
    let promoCode = await API.getItem(controllerPath, data)

    if (promoCode.contact) {
      promoCode.contact = promoCode.contact.id
    }
    return promoCode
  }
  catch (e) {
    console.error(e)
  }
}

export function createItem(data) {
  return API.createItem(controllerPath, data);
}

export function updateItem(id, data) {
  return API.updateItem(controllerPath, id, data);
}

export function deleteItem(id) {
  return API.deleteItem(controllerPath, id);
}

export function generate(count) {
  return API.downloadFile(
    `${controllerPath}/generate`,
    {count},
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'promo_codes.xlsx'
  );
}